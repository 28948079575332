<!-- 机构用户创建项目 -->
<template>
	<div class="index-page">
		<div class="steps-content">
			<template v-for="(step, index) in steps">
				<div :class="['step-item', activeStepIndex === index ? 'active' : '']" :key="index">
					<div class="step-name">{{ index + 1 }}.{{ step }}</div>
					<div class="step-arrow" v-if="index < steps.length - 1">
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</template>
		</div>
		<div class="upload-content" v-show="activeStepIndex === 0">
			<create-upload ref="createUploadDom" @action="executeAction"></create-upload>
			<div class="next-btn">
				<el-button type="primary" round :loading="nextBtnLoading" @click="nextBtnEvent" v-if="Object.keys(fileData).length > 0">下一步</el-button>
			</div>
		</div>
        <div class="setting-content" v-show="activeStepIndex === 1">
            <el-card class="none-boder radius-12 margin-t-20" :body-style="{padding:'30px'}">
                <div class="project-info margin-b-20">
                    <div class="flex margin-b-10">
                        <div class="flex-1">
                            <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">项目名称</div>
                                <div>
                                    <el-input ref="defaultProjectName" size="small" v-model="defaultProjectName" placeholder="输入项目名称" autofocus="true"></el-input>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <div class="flex margin-b-10">
                        <div class="flex-1">
                            <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">项目流程</div>
                                <div>
                                    <el-select v-model="translationProcess" size="small" placeholder="选择项目流程">
                                        <template v-for="item in workflowOptions">
                                            <el-option :label="item.description" :value="item.name" :key="item.name"></el-option>
                                        </template>
                                    </el-select>
                                </div>
                            </el-card>
                        </div>
                        <div class="flex-1 margin-l-10">
                            <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">所属团队</div>
                                <div>
                                    <el-select v-model="teamId" size="small" placeholder="选择团队" @change="teamChangeEvent">
                                        <template v-for="item in teamOptions">
                                            <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                                        </template>
                                    </el-select>
                                </div>
                            </el-card>
                        </div>
                        <div class="flex-1 margin-l-10">
                             <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">截止日期</div>
                                <div>
                                    <el-date-picker 
                                        size="small"
                                        v-model="deadline"
                                        type="date"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <div class="language-setting-content">
                        <div class="flex padding-x-20 padding-t-10">
                            <div class="flex-1">
                                <div class="margin-b-10 fonts-12">源语言</div>
                                <div>
                                    <el-select v-model="form.originValue" filterable size="small" placeholder="选择源语言" @change="originValueChangeEvent" style="width: 100%;">
                                        <template v-for="(value, key) in LANGUAGE_DICT">
                                            <el-option :label="value.name" :value="value.key" :key="key"></el-option>
                                        </template>
                                    </el-select>
                                </div>
                            </div>
                            <div class="target-select flex-1 margin-l-20">
                                <div class="margin-b-10 fonts-12">目标语言</div>
                                <div>
                                    <el-select v-model="form.targetValue" multiple collapse-tags filterable size="small" placeholder="选择目标语言" @change="targetValueChangeEvent" style="width: 100%;">
                                        <template v-for="(value, key) in LANGUAGE_DICT">
                                            <el-option :label="value.name" :value="value.key" :key="key" v-if="value.key !== form.originValue"></el-option>
                                        </template>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="language-list">
                            <template v-for="(item, index) in languageEngineList">
                                <div class="item" :key="index">
                                    <div class="flex-1 fonts-15">
                                        <language-direction :source="form.originValue" :target="item.target"></language-direction>
                                    </div>
                                    <el-tooltip content="对比引擎" placement="top">
                                        <div class="compare-btn" @click="compareEngine({source: form.originValue, ...item})">
                                            <img src="../../../static/images/engine/translation-score-icon.png" alt="compare icon" />
                                            <!-- <svg-icon name="ib-robot"></svg-icon> -->
                                        </div>
                                    </el-tooltip>
                                    <div class="engine-select">
                                        <el-select v-model="item.engine" value-key="key" size="small" placeholder="选择预翻译引擎">
                                            <template slot="prefix" v-if="item.engine && item.engine.icon">
                                                <svg-icon :name="`ie-${item.engine.icon}`" className="fonts-20"></svg-icon>
                                            </template>
                                            <el-option-group>
                                                <el-option label="人工翻译" value="NONE" key="NONE"></el-option>
                                            </el-option-group>
                                            <template v-for="domain in item.options">
                                                <el-option-group :label="domain.name" :key="domain.key" v-if="domain.engines.length > 0">
                                                    <el-option v-for="engine in domain.engines" :value="engine" :key="engine.key" :label="engine.name">
                                                        <div class="flex flex-align-center">
                                                            <svg-icon :name="`ie-${engine.icon}`" className="margin-r-10 fonts-20"></svg-icon>
                                                            <span>{{engine.name}}</span>
                                                        </div>
                                                    </el-option>
                                                </el-option-group>
                                            </template>
                                        </el-select>
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>
                    
                    <!-- <div class="margin-t-10">
                        <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                            <div class="margin-b-10 fonts-12">备注说明</div>
                            <div>
                                <el-input v-model="remark" maxlength="200" show-word-limit placeholder="输入项目备注"></el-input>
                            </div>
                        </el-card>
                    </div> -->
                </div>
                <div class="">
                    <div class="tab-list">
                        <template v-for="(item, index) in tabData.tabs">
                            <div :class="['item', tabData.tabActiveIndex === index ? 'active' : '']" :key="index" @click="settingTabEvent(item, index)">{{item}}</div>
                        </template>
                    </div>
                    <div v-show="tabData.tabActiveIndex === 0">
                        <div class="fonts-14 margin-t-15">系统会自动创建该项目默认记忆库，并将项目新产生的翻译记忆写入此库。</div>
                        <div class="flex flex-justify-between margin-t-15">
                            <div>
                                <el-button size="mini" plain round @click="gotoMemoryPage" icon="el-icon-plus">新建</el-button>
                                <el-button size="mini" plain round @click="initMemoryList" icon="el-icon-refresh-right">刷新</el-button>
                            </div>
                            <div class="fonts-13 mem-matching-setting">
                                <span class="margin-r-10"><span class="color-error fonts-16" style="margin-right:1px;">*</span>匹配率</span>
                                <el-select placeholder="匹配率" v-model="memMatchingRate" size="mini" style="width:78px;">
                                    <template v-for="item in memMatchingOptions">
                                        <el-option :label="item" :value="item" :key="item"></el-option>
                                    </template>
                                </el-select>
                            </div>
                        </div>
                        <el-table ref="memoryTable" :data="tabData.memoryList" max-height="480" style="width: 100%" @selection-change="handleMemoryTableSelectionChange">
                            <el-table-column type="selection" width="42"></el-table-column>
                            <el-table-column label="写入" width="60">
                                <template slot-scope="scope">
                                    <el-switch :value="scope.row.memId === writeableMemId" active-color="#1AC49C" @change="val => handleMemoryWriteChange(val, scope.row)"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="名称">
                                <template slot-scope="scope">
                                    <router-link :to="{path:'/asset/memory/detail',query:{id:scope.row.memId}}" target="_blank">{{scope.row.name}}</router-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="语言方向">
                                <template slot-scope="scope">
                                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang"></language-direction>
                                </template>
                            </el-table-column>
                            <el-table-column label="条数" prop="nums" width="90">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.nums }}条</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="罚分" width="110">
                                <template slot-scope="scope">
                                    <el-input-number size="mini" :disabled="getMemoryPenaltyPointsInputStatus(scope.row)" v-model="scope.row.setting[0].penaltyPoints" @change="val => handleMemoryPenaltyPointsChange(val, scope.row)" controls-position="right" :step="1" :precision="0" :min="0" :max="15" style="width: 90px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" prop="createTime" width="160"></el-table-column>
                        </el-table>
                    </div>
                    <div v-show="tabData.tabActiveIndex === 1">
                        <el-alert type="info" effect="dark" :closable="false">
                            <template slot="title">
                                <div class="flex flex-align-center" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">
                                    <svg-icon name="icon-free" style="font-size:22px;" class="margin-r-10"></svg-icon>项目术语会在编辑器提示，但当前版本不支持术语干预，<a href="/account/vip" target="_blank">升级到PRO解锁本功能</a>
                                </div>
                                <div class="flex flex-align-center" v-else>
                                    <svg-icon name="ic-pro" style="font-size:22px;" class="margin-r-10"></svg-icon>{{userInfo.accountTypeDesc}}已支持术语干预
                                </div>
                            </template>
                        </el-alert>
                        <div class="fonts-14 margin-t-15">系统会自动创建该项目默认术语库，并将项目新产生的术语写入此库。</div>
                        <div class="margin-t-15">
                            <el-button size="mini" plain round @click="gotoTermPage" icon="el-icon-plus">新建</el-button>
                            <el-button size="mini" plain round @click="initTermsList" icon="el-icon-refresh-right">刷新</el-button>
                        </div>
                        <el-table ref="termsTable" :data="tabData.termsList" max-height="480" style="width: 100%" @selection-change="handleTermsTableSelectionChange">
                            <el-table-column type="selection" width="42"></el-table-column>
                            <el-table-column label="写入" width="60">
                                <template slot-scope="scope">
                                    <el-switch :value="scope.row.termId === writeableTermId" active-color="#1AC49C" @change="val => handleTermWriteChange(val, scope.row)"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="名称">
                                <template slot-scope="scope">
                                    <router-link :to="{path:'/asset/term/detail',query:{id:scope.row.termId}}" target="_blank">{{scope.row.name}}</router-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="语言方向">
                                <template slot-scope="scope">
                                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang"></language-direction>
                                </template>
                            </el-table-column>
                            <el-table-column label="条数" prop="nums" width="90">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.nums }}条</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" prop="createTime" width="160"></el-table-column>
                        </el-table>
                    </div>
                    <div class="padding-t-10" v-show="tabData.tabActiveIndex === 2">
                        <!-- <div class="padding-b-15 border-b fonts-14">拆分规则</div>
                        <div class="doc-config-card" shadow="never">
                            <div class="flex flex-align-center">
                                <div class="flex-1">
                                    <el-radio-group v-model="docSplit">
                                        <el-radio label="SENTENCE">按句拆分</el-radio>
                                        <el-radio label="PARAGRAPH">按段拆分</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="padding-y-15 border-b fonts-14">文档设置</div> -->
                        <template v-for="(item, index) in docSettingOpts">
                            <div class="doc-config-card" shadow="never" :key="index">
                                <div class="flex flex-align-center">
                                    <el-tooltip :content="item.name" placement="top">
                                        <div class="doc-icon file-word flex flex-justify-center flex-align-center">
                                            <svg-icon :name="'ic-' + item.icon" className="fonts-17"></svg-icon>
                                        </div>
                                    </el-tooltip>
                                    <div class="check-all margin-l-30" style="width: 87px;">
                                        <el-checkbox v-model="item.checkAll" :indeterminate="item.indeterminate" @change="val=>docSettingCheckAllChange(val,index)">全选</el-checkbox>
                                    </div>
                                    <div class="flex-1">
                                        <div class="options-list">
                                            <template v-for="(opt, oIndex) in item.options">
                                                <div class="margin-r-30"  :key="oIndex">
                                                    <el-checkbox v-model="opt.value" :label="opt.value" @change="val=>docSettingCheckChange(val,index,oIndex)">{{opt.label}}</el-checkbox>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="padding-t-10" v-show="tabData.tabActiveIndex === 3">
                        <div class="qa-setting margin-t-10">
                            <div class="block-title">规则QA设置</div>
                            <div class="option-list">
                                <template v-for="(item,index) in qaSetting.basicList">
                                    <div class="item" :key="index">
                                        <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description"></el-checkbox>
                                    </div>
                                </template>
                            </div>
                            <div class="block-title">
                                智能QA设置
                                <!-- <el-tooltip content="智能QA只对Pro版开放，Free用户QA只提供规则QA" placement="top">
                                    <span style="color:#000;">
                                        <i class="el-icon-info"></i>
                                    </span>
                                </el-tooltip> -->
                            </div>
                            <div class="option-list">
                                <template v-for="(item,index) in qaSetting.aiList">
                                    <div class="item" :key="index">
                                        <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description"></el-checkbox>
                                    </div>
                                </template>
                            </div>
                            <!-- <div style="font-size:12px;">
                                <svg-icon name="ib-warning" style="width: 14px;height: 14px;fill:#FDB500;"></svg-icon> 英文流利度（包括用词搭配、时态、单复数、句首大小、代词/冠词、英文流利度检查）
                            </div> -->
                        </div>
                    </div>
                    <div class="padding-t-15" v-show="tabData.tabActiveIndex === 4">
                        <el-image style="display:none;" ref="backupEngineExample"
                            src="https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/backup-engine-example.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101677055427&Signature=fKZxWhwx5HfiOfUw6lmMV%2FkU8KA%3D"
                            :preview-src-list="[
                                'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/backup-engine-example.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101677055427&Signature=fKZxWhwx5HfiOfUw6lmMV%2FkU8KA%3D'
                            ]"></el-image>
                        <div class="margin-b-15 fonts-12 color-666666">选择编辑器内参考引擎，如果不选择系统会自动推荐。<a href="javascript:;" class="margin-l-10" @click="$refs.backupEngineExample.clickHandler()">预览</a></div>
                        <div class="language-list" style="border: none;">
                            <template v-for="(item, index) in languageEngineList">
                                <div class="item" :key="index">
                                    <div class="flex-1 fonts-15">
                                        <language-direction :source="form.originValue" :target="item.target"></language-direction>
                                    </div>
                                    <div class="engine-select">
                                        <el-select v-model="item.referenceEngine" value-key="key" size="small" placeholder="选择参考引擎" @focus="initReferenceEngineList(item)" style="width: 100%">
                                            <template slot="prefix" v-if="item.referenceEngine && item.referenceEngine.icon">
                                                <svg-icon :name="`ie-${item.referenceEngine.icon}`" className="fonts-20"></svg-icon>
                                            </template>
                                            <template v-for="domain in item.referenceOptions">
                                                <el-option v-for="engine in domain.engines" :value="engine" :key="engine.key" :label="engine.name">
                                                    <div class="flex flex-align-center">
                                                        <svg-icon :name="`ie-${engine.icon}`" className="margin-r-10 fonts-20"></svg-icon>
                                                        <span>{{engine.name}}</span>
                                                    </div>
                                                </el-option>
                                            </template>
                                        </el-select>
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>
                    <div class="padding-t-15" v-show="tabData.tabActiveIndex === 5">
                        <div class="remark-input">
                            <el-input v-model="remark" type="textarea" maxlength="500" show-word-limit :rows="3" resize="none" placeholder="输入项目备注，500字以内"></el-input>
                        </div>
                        <div class="padding-y-15 border-b">
                            <el-button plain round size="mini" icon="el-icon-plus" @click="remarkFileUpload.show = true">添加文件</el-button>
                        </div>
                        <el-table :data="remarkFileList" style="width: 100%;">
                          <el-table-column label="序号" type="index"></el-table-column>
                          <el-table-column label="文件名" prop="fileName"></el-table-column>
                          <el-table-column label="创建时间" prop="time"></el-table-column>
                          <el-table-column label="操作" width="100px">
                            <template slot-scope="scope">
                                <el-button type="text" size="mini" @click="deleteRemarkFile(scope)">
                                    <svg-icon name="ib-trash-o" className="fonts-16"></svg-icon>
                                </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                        <el-dialog title="上传风格指南文件" :visible.sync="remarkFileUpload.show" destroy-on-close :width="'520px'" top="20px">
                            <div class="fonts-12 margin-b-15 text-center">当前项目最多可上传 <span class="color-link">10</span> 个文件，如有多个文件建议上传压缩包</div>
                            <!-- <div class="fonts-12 margin-b-15 text-center">{{remarkFileUpload.supportFiles}}</div> -->
                            <upload-oss ref="remarkFileUploadDom"
                                :multiple="true"
                                :limit="10 - remarkFileList.length"
                                :size="50"
                                :accept="remarkFileUpload.accept"
                                :params="{}"
                                :type="`STYLE_GUIDE`"
                            ></upload-oss>
                            <div slot="footer" class="text-center">
                                <el-button round plain size="small" @click="remarkFileUpload.show = false">取消</el-button>
                                <el-button round type="primary" size="small" :loading="remarkFileUpload.loading" @click="submitUploadRemarkFile">确定</el-button>
                            </div>
                        </el-dialog>
                    </div>
                </div>
            </el-card>
            <div class="action-content" v-if="activeStepIndex === 1">
                <el-button plain round @click="backBtnEvent">上一步</el-button>
                <el-button type="primary" round @click="startTransitionBtnEvent" :loading="startTranslationBtnLoading" style="margin-left: 20px">开始翻译</el-button>
            </div>
        </div>
        
		<create-memory ref="createMemoryDialog" @success="initMemoryList"></create-memory>
        <create-term ref="createTermDialog" @success="initTermsList"></create-term>
		<alert-dialog
			ref="alertDialogDom"
			:message="alertInfo.message"
			:submessage="alertInfo.submessage"
			:type="alertInfo.type"
			:buttonName="alertInfo.buttonName"
			:buttonType="alertInfo.buttonType"
			@confirmEvent="confirmNextEvent"
		></alert-dialog>
	</div>
</template>
<script>
import { debounce } from 'lodash';
import { mapGetters, mapState } from "vuex";
import $ from "jquery";
import CreateUpload from './components/CreateUpload.vue';
import JargonDialog from "@/components/JargonDialog";
import AlertDialog from "@/components/AlertDialog";
import PageTitle from "@/components/PageTitle";
import LanguageSelector from "@/components/LanguageSelector";
import CreateTerm from '@/views/project/components/CreateTermMultiLanguage';
import CreateMemory from '@/views/project/components/CreateMemoryMultiLanguage';
import LanguageDirection from '@/components/LanguageDirection';
import UploadOss from '@/components/UploadOss';
import config from "@/utils/config";
import tools from "@/utils/tools";
import { commonApi, projectApi, docApi, termApi, memoryApi, qaApi } from "@/utils/api";
import moment from 'moment';

export default {
	data() {
		return {
            uploadFileList: [],
            fileData: {},
			steps: ['上传文件', '项目设置'],
			activeStepIndex: 0,
            nextBtnLoading: false,
			engineList: [],
			defaultProjectName: "",

			form: {
				originValue: "zh",
				targetValue: [],
			},
            languageEngineList: [],
            defaultSelectedEngine: {"key": "chatgpt", "engine": null, "name": "ChatGPT", "icon": "chatgpt", "sort": 100, "domain": "general", "modelType": null, "modelId": null, "enabled": true, "commonly": false, "proUsed": false},
			
			startTranslationBtnLoading: false,
			advancedIsShow: false,
            memMatchingRate: 80,
            memMatchingOptions: [50, 60, 70, 80, 90, 100,],
            writeableMemId: null,
            writeableTermId: null,
			tabData: {
                tabs: ['记忆库', '术语库', '文档设置', 'QA设置', '参考引擎', '风格指南'],
				tabActiveIndex: 0,
				termsList: [],
				termsTableSelection: [],
				memoryList: [],
				memoryTableSelection: [],
                memoryTableSelectionIds: [], 
			},
			alertInfo: {
				message: "当前上传列表中有上传中或上传失败的文件，是否继续？",
				submessage: "点击继续按钮，上传中或上传失败的文件不会带到下一步。",
				type: "confirm", //alert ,confirm
				buttonName: "继续",
				buttonType: "primary",
			},
			
            createProjectApiSuccess: false,

            teamId: '',
            teamOptions: [],

            workflowOptions: [],
            translationProcess: 'T',

            deadline: '',
            remark: '',
            remarkFileUpload: {
                show: false,
                loading: false,
                accept: '', //'.txt,.docx,.doc,.pdf,.rtf,.xlsx,.xls,.ppt,.pptx,.csv,.srt,.sdlxliff,.xliff,.cad,.dita,.ai,.indd,.mpp',
                supportFiles: 'txt/docx/doc/pdf/rtf/xlsx/xls/ppt/pptx/csv/srt/sdlxliff/xliff/cad/dita/ai/indd/mpp',
            },
            remarkFileList: [],
            docSplit: 'SENTENCE',
            docSplitOpts: config.DOC_SPLIT_OPTS,
            docSettingOpts: config.DOC_SETTING_OPTS || [],
            qaSetting: {
                basicList:[],
                aiList:[],
            },
            //内部机构从CRM跳过来时有用
            projectInfo: {},

            referenceEngineList: [],
		};
	},
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
            userInfo: state => state.user.userData,
        }),
	},
	components: {
		JargonDialog,
		PageTitle,
		AlertDialog,
		LanguageSelector,
        CreateTerm,
        CreateMemory,
        CreateUpload,
        LanguageDirection,
        UploadOss,
	},
	methods: {
		async nextBtnEvent() {
            if (this.activeStepIndex === 0){
                let {fileList, fileData} = this.$refs.createUploadDom.getValue();
                let keys = Object.keys(fileData || {});
                if (keys.length < fileList.length) {
                    this.$refs.alertDialogDom.setShow(true);
                } else {
                    if (keys.length > 0) {
                        let list = [];
                        keys.map(key=>{
                            list.push(fileData[key]);
                        });
                        if (list.length > 1){
                            list.sort((a,b)=>{
                                return a.size - b.size;
                            });
                        }
                        this.nextBtnLoading = true;
                        let url = `${docApi.getDocLanguage}?objectName=${list[0].objectName}`;
                        let res = await this.$ajax.get(url);
                        if (res.status === 200){
                            let lang = res.data;
                            if (lang === 'unknown'){
                                this.form.originValue = 'zh';
                                
                            }else{
                                this.form.originValue = lang;
                                if (lang === this.form.targetValue){
                                    if(lang === 'zh'){
                                        this.form.targetValue = 'en';
                                    }else{
                                    this.form.targetValue = 'zh'; 
                                    }
                                }
                            }
                            this.activeStepIndex = this.activeStepIndex + 1;
                            // console.log('nextBtn:::',this.uploadFileList[0])
                            let _name = fileList[0].name;
                            let _names = _name.split('.');
                            _names.pop();
                            this.defaultProjectName = _names.join('.');
                            this.$nextTick(()=>{
                                this.$refs.defaultProjectName.focus();
                            });
                            // 初始换术语库记忆库
                            this.initTermsList();
                            this.initMemoryList();
                        }
                        this.nextBtnLoading = false;
                    } else {
                        if (fileList.length > 0) {
                            this.$message.error("请检查文件是否上传成功");
                        } else {
                            this.$message.error("请上传文件");
                        }
                    }
                }
            }else if(this.activeStepIndex === 1){
                if(!this.selectedEngine){
                    this.$message.error('请选择引擎');
                    return;
                }

                this.docSplit = 'SENTENCE';
                if(this.selectedEngine.key === 'chatgpt') {
                    this.docSplit = 'PARAGRAPH';
                }

                this.activeStepIndex = this.activeStepIndex + 1;
                this.$nextTick(()=>{
                    this.$refs.defaultProjectName.focus();
                });
                // 初始换术语库记忆库
                this.initTermsList();
                this.initMemoryList();
            }else{
                this.activeStepIndex = this.activeStepIndex + 1;
            }
		},
		confirmNextEvent() {
            let {fileList, fileData} = this.$refs.createUploadDom.getValue();
			let keys = Object.keys(fileData || {});
			if (keys.length > 0) {
                let list = [];
                keys.map(key=>{
                    list.push(fileData[key]);
                });
                if (list.length > 1){
                    list.sort((a,b)=>{
                        return a.size - b.size;
                    });
                }
                let url = `${docApi.getDocLanguage}?objectName=${list[0].objectName}`;
                this.nextBtnLoading = true;
                this.$ajax.get(url).then(res => {
                    if (res.status === 200){
                        let lang = res.data;
                        if (lang === 'unknown'){
                            this.form.originValue = 'zh';
                        }else{
                            this.form.originValue = lang;
                            if (lang === this.form.targetValue){
                                if(lang === 'zh'){
                                    this.form.targetValue = 'en';
                                }else{
                                   this.form.targetValue = 'zh'; 
                                }
                            }
                        }
                        this.activeStepIndex = 1;
                        let _name = fileList[0].name;
                        let _names = _name.split('.');
                        _names.pop();
                        if (!this.defaultProjectName) {
                            this.defaultProjectName = _names.join('.');
                        }
                    }
                }).finally(()=>{
                    this.nextBtnLoading = false;
                });  
				
			} else {
				if (fileList.length > 0) {
					this.$message.error("请检查文件是否上传成功");
				} else {
					this.$message.error("请上传文件");
				}
			}
		},
        
		backBtnEvent() {
			this.activeStepIndex = this.activeStepIndex - 1;
		},
        /**
         * 开始翻译按钮事件
         */
		startTransitionBtnEvent: debounce(function() {
			let self = this;
            if(!this.defaultProjectName) {
                this.$message.error('请输入项目名称');
                return;
            }
            if(!this.deadline) {
                this.$message.error('请选择截止日期');
                return;
            }
            if(this.form.targetValue.length == 0) {
                this.$message.error('请选择目标语言');
                return;
            }
            // console.log (this.languageEngineList);
            let vEngines = this.languageEngineList.filter(item => item.engine === null);
            if(vEngines.length > 0){
                this.$message.error('请为已选择的语言方向设置预翻译引擎');
                return;
            }
			let url = projectApi.startTranslation;
			let docs = [];
            if(this.projectInfo && this.projectInfo.docs){ 
                docs = this.projectInfo.docs;
            }else {
                let keys = Object.keys(this.fileData || {});
                keys.map((key) => {
                    docs.push(this.fileData[key]);
                });
            }
			let postData = {
                projectName: this.defaultProjectName,
                teamId: this.teamId,
                deadline: moment(this.deadline).format('YYYY-MM-DD'),
                remark: this.remark,
				docs: docs,
                styleGuideDocs: this.remarkFileList,
			};
            if(this.projectInfo) {
                postData.externalProjectId = this.projectInfo.externalProjectId;
            }
			let projectSetting = {
                translationProcess: this.translationProcess,
                writeableMemId: this.writeableMemId,
                writeableTermId: this.writeableTermId,
			};
            let multilingualSetting = [];
			let termIds = [];
			this.tabData.termsTableSelection.map((item) => {
				termIds.push(item.termId);
			});
			let memIds = this.tabData.memoryTableSelectionIds;
            let mems = [];
			this.tabData.memoryTableSelection.map((item) => {
				mems.push({memId: item.memId, penalties: item.setting});
			});
            this.languageEngineList.map(item => {
                multilingualSetting.push({
                    sourceLang: this.form.originValue,
                    targetLang: item.target,
                    enableAiTrans: item.engine !== 'NONE',
                    engine: item.engine !== 'NONE' && item.engine.key ? item.engine.key.split("@")[0] : null,
                    modelId: item.engine !== 'NONE' && item.engine.modelId ? item.engine.modelId : null,
                    domain:  item.engine !== 'NONE' ? item.engine.domain : null,
                    randomEngine: item.referenceEngine ? item.referenceEngine.key : null,
                    termIds,
                    memIds,
                    mems,
                    memMatchingRate: this.memMatchingRate,
                });
            });
            projectSetting['multilingualSetting'] = multilingualSetting;
            //QA
            //projectSetting['qaSetting'] = {options: [].concat(this.qaSetting.basicList, this.qaSetting.aiList)};
            let optionsObject = {};
            [].concat(this.qaSetting.basicList, this.qaSetting.aiList).forEach(option => {
                optionsObject[option.code] = option.selected;
            });
            projectSetting['qaSetting'] = {selected: optionsObject};
            let docSetting = {};
            this.docSettingOpts.map(item=>{
                item.options.map(opt=>{
                    docSetting[opt.key] = opt.value;
                });
            });
            projectSetting['docSetting'] = docSetting;
			postData.projectSetting = projectSetting;
            // console.log('postData::', postData);
            // return;
			this.startTranslationBtnLoading = true;
			this.$ajax.post(url, postData).then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        this.createProjectApiSuccess = true;
                        self.$router.push(`/project/index`);
                    }, 1000);
                }
            }).finally(() => {
                this.startTranslationBtnLoading = false;
            });
		}),
		initTermsList() {
			this.termsTableSelection = [];
			let url = `${termApi.termDB}?pageNumber=1&pageSize=1000` +
				`&sourceLang=${this.form.originValue}&targetLang=${this.form.targetValue.join(',')}&teamId=${this.teamId}`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.tabData.termsList = res.data.records;
					if (res.data.records.length > 0) {
						this.advancedIsShow = true;
					}
                    if (res.data.records.length > 0){
                        this.$nextTick(() =>{
                            if(arguments.length){
                                let termIds = arguments[0]?.ids || [];
                                for(let item of this.tabData.termsList){
                                    if(termIds.indexOf(item.termId) > -1){
                                        this.$refs.termsTable.toggleRowSelection(item, true);
                                    }
                                }
                            }
                        });
                    }
				}
			});
		},
		initMemoryList() {
			this.tabData.memoryTableSelection = [];
			let url = `${memoryApi.memoryDB}?pageNumber=1&pageSize=1000&type=new` +
				`&sourceLang=${this.form.originValue}&targetLang=${this.form.targetValue.join(',')}&teamId=${this.teamId}`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.tabData.memoryList = res.data.records;
                    if (res.data.records.length > 0){
                        this.$nextTick(() =>{
                            if(arguments.length){
                                let memIds = arguments[0]?.ids || [];
                                for(let item of this.tabData.memoryList){
                                    if(memIds.indexOf(item.memId) > -1){
                                        this.$refs.memoryTable.toggleRowSelection(item, true);
                                    }
                                }
                            }
                        });
                    }
				}
			});
		},
        handleMemoryWriteChange(val, row) {
            if(val){
                this.writeableMemId = row.memId;
                this.$refs.memoryTable.toggleRowSelection(row, true);
            }else {
                this.writeableMemId = null;
            }
        },
        handleTermWriteChange (val, row) {
            if(val){
                this.writeableTermId = row.termId;
                this.$refs.termsTable.toggleRowSelection(row, true);
            }else {
                this.writeableTermId = null;
            }
        },
		gotoTermPage() {
            if(this.form.targetValue.length === 0){
                this.$message.warning('请选择目标语言');
                return;
            }
            let list = [];
            this.form.targetValue.map(target => {
                list.push({source: this.form.originValue, target, object: {}});
            })
            this.$refs.createTermDialog.setShow({list});
		},
		gotoMemoryPage() {
            if(this.form.targetValue.length === 0){
                this.$message.warning('请选择目标语言');
                return;
            }
            let list = [];
            this.form.targetValue.map(target => {
                list.push({source: this.form.originValue, target, object: {}});
            })
            this.$refs.createMemoryDialog.setShow({list});
		},
		handleTermsTableSelectionChange(val) {
			this.tabData.termsTableSelection = val;
		},
		handleMemoryTableSelectionChange(val) {
			this.tabData.memoryTableSelection = val;
            this.tabData.memoryTableSelectionIds = val.map(item => item.memId);
		},
        getMemoryPenaltyPointsInputStatus(row) {
            return this.tabData.memoryTableSelectionIds.indexOf(row.memId) < 0;
        },
        handleMemoryPenaltyPointsChange(val, row) {
            console.log(val, row);
            let index = this.tabData.memoryTableSelection.findIndex(item => item.memId === row.memId);
            this.tabData.memoryTableSelection.splice(index, 1, row);
        },
        docSettingCheckAllChange(val,index){
            this.docSettingOpts[index]['checkAll'] = val;
            this.docSettingOpts[index]['indeterminate'] = false;
            let tmpOptions = this.docSettingOpts[index]['options'];
            tmpOptions.map((opt, oIndex)=>{
                tmpOptions[oIndex]['value'] = val;
            });
            this.docSettingOpts[index]['options'] = tmpOptions;
        },
        docSettingCheckChange(val,index,oIndex){
            let checkedCount = 0;
            this.docSettingOpts[index]['options'][oIndex]['value'] = val;
            let options = this.docSettingOpts[index]['options'];
            options.map(opt=>{
                if(opt.value === true){
                    checkedCount = checkedCount + 1;
                }
            });
            this.docSettingOpts[index]['checkAll'] = checkedCount === options.length;
            this.docSettingOpts[index]['indeterminate'] = checkedCount > 0 && checkedCount < options.length;
        },
        initQASetting(){
            let url = `${qaApi.queryQaConfig}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let list = res.data.options;
                    list.map(item=>{
                        if(item.type === 'BASIC'){
                            this.qaSetting.basicList.push(item);
                        }else if(item.type === 'AI'){
                            this.qaSetting.aiList.push(item);
                        }
                    });
                }
            })
        },
        initReferenceEngineList(item){
            console.log(item);
            let postData = {
				sourceLang: this.form.originValue,
				targetLang: item.target,
            }
            if(item.engine !== 'NONE') {
                postData = {
                    ...postData,
                    domain: item.engine.domain,
                    engine: item.engine.key.split("@")[0],
                    modelId: item.engine.modelId ? item.engine.modelId : undefined,
                }
            }
            let url = projectApi.queryReferenceEngineList;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    item.referenceOptions = res.data;
                }
            })
        },
        settingTabEvent(item, index){
            this.tabData.tabActiveIndex = index;
        },
        queryWorkflowOptions() {
            let url = projectApi.queryWorkflowList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.workflowOptions = res.data;
                }
            });
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamFilters;
                    this.teamId = this.teamOptions[0].value
                }
            });
        },
        teamChangeEvent(){
            this.initTermsList();
            this.initMemoryList();
        },

        // 内部机构从CRM跳转到Lanx新建项目
        initProjectInfo() {
            let url = `${projectApi.queryProjectInitData}?id=${this.$route.query.projectId}&userId=${this.$route.query.uId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.projectInfo = res.data;
                    this.form.originValue = res.data.sourceLang;
                    this.form.targetValue = res.data.targetLang ? res.data.targetLang.split(",") : [];
                    this.defaultProjectName =res.data.projectName;
                    this.remarkFileList = res.data.styleGuideDocs || [];
                    this.deadline = res.data.endDate || '';
                    this.$nextTick(() => {
                        this.targetValueChangeEvent(this.form.targetValue);
                        this.activeStepIndex = 1;
                    })
                }
            });
        },
        originValueChangeEvent(value){
            let tIndex = this.form.targetValue.findIndex(item => item === value);
            if(tIndex > -1){
                this.form.targetValue.splice(tIndex, 1);
            }
            let eIndex = this.languageEngineList.findIndex(item => item.target === value);
            if(eIndex > -1){
                this.languageEngineList.splice(eIndex, 1);
            }
        },
        // 目标语言改变事件
        targetValueChangeEvent(value){
            let list = [];
            if(value){
                value.map(async item => {
                    let langEngine = this.languageEngineList.find(lang => lang.target === item);
                    if(langEngine){
                        list.push(langEngine);
                    }else {
                        let url = `${projectApi.queryEnginesAndRecommendByLang}?&sourceLang=${this.form.originValue}&targetLang=${item}`;
                        const res = await this.$ajax.get(url);
                        list.push({target: item, engine: res.data.recommendationEngine, options: res.data.domainEngines, referenceEngine: null, referenceOptions: []});
                    }
                });
            }
            this.languageEngineList = list;
            this.initMemoryList();
            this.initTermsList();
        },
        compareEngine(param) {
            let url = `/tools/translationScore/index`; //?source=${param.source}&target=${param.target}
            window.open(url, '_blank');
        },
        /* loadEngineList(item){
            let url = `${projectApi.queryEnginesByLang}?&sourceLang=${this.form.originValue}&targetLang=${item.target}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    item.options = res.data;
                }
            })
        }, */
        submitUploadRemarkFile(){
            let files = this.$refs.remarkFileUploadDom.getOssFiles();
            let list = [];
            files.map(item => {
                list.push({...item, time: moment().format('YYYY-MM-DD HH:mm:ss')});
            });
            this.remarkFileList = this.remarkFileList.concat(list);
            this.remarkFileUpload.loading = false;
            this.remarkFileUpload.show = false;
        },
        deleteRemarkFile(scope) {
            let index = scope.$index;
            this.remarkFileList.splice(index, 1);
        },

        executeAction(action, params){
            switch (action){
                case 'updateLang':
                    this.form = { ...this.form, ...params, };
                    break;
                case 'updateFile':
                    this.uploadFileList = params.fileList;
                    this.fileData = params.fileData;
                    break;
            }
            this.$forceUpdate();
        },
        
	},
	beforeRouteLeave: function (to, from, next) {
        try{
            if (!this.createProjectApiSuccess){
                if (this.uploadFileList.length > 0) {
                    // next(false);
                    this.$confirm("确定要离开吗？离开会清空当前配置信息", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        next();
                    });
                } else {
                    next();
                }
            }else{
                next();
            }
        }catch(e){
            console.error('页面跳转提醒：',e);
        }
	},
    created(){
        let query = this.$route.query;
        if(query && query.source === 'crm' && query.projectId){
            this.initProjectInfo();
        }
        this.initQASetting();
    },
	mounted() {
        this.queryWorkflowOptions();
        this.queryTeamOptions();
	},
	beforeDestroy() {
		
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.index-page {
	// position: relative;
	// margin: 20px;
	display: flex;
	height: 100%;
	flex-direction: column;
    .try-content {
		::v-deep .el-textarea__inner {
			height: 100%;
			border: none;
			background-color: inherit;
			padding: 20px;
			font-size: 14px;
			color: #000000;
		}
		p {
			line-height: 22px;
			&:empty {
				height: 22px;
			}
		}
	}
	.steps-content {
		width: 100%;
		display: flex;
        margin-top: 30px;
        justify-content: center;
		.step-item {
			display: flex;
			font-size: 15px;
			color: #00000066;
			&.active {
				.step-name {
					font-weight: 600;
					color: #000;
				}
			}
			.step-arrow {
				width: 44px;
				text-align: center;
			}
		}
	}
	.upload-content {
		display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
	}
    .next-btn {
        text-align: center;
        margin-top: 20px;
        height: 40px;
    }
	.engine-content {
        margin-top: 40px;
		margin-bottom: 100px;
		.from-content {
			display: flex;
			justify-content: center;
			align-items: center;
			.transition-icon {
				width: 64px;
				cursor: pointer;
				text-align: center;
				& > i {
					font-size: 18px;
				}
				&[disabled] {
					cursor: not-allowed;
					pointer-events: none;
					opacity: 0.5;
				}
			}
			.terminology-config {
				margin-left: 80px;
			}
		}
		.try-content {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			.left-content {
				flex: 1;
				display: flex;
				flex-direction: column;
				max-width: 400px;
				min-width: 250px;
				min-height: 304px;
				background: #ffffff;
				border: 1px solid rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				border-radius: 8px;
				& > .input-content {
					flex: 1;
					position: relative;
					.el-textarea {
						height: 100%;
					}
					> .clear-btn {
						position: absolute;
						width: 30px;
						height: 30px;
						top: 0;
						right: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}
				& > .action-content {
					display: flex;
                    justify-content: space-between;
					margin: 20px;
					font-size: 12px;
					> .random-doc-btn {
						text-align: left;
						cursor: pointer;
                        color: $a-color;
						&[disabled]{
                            opacity: .5;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                        
					}
                    > .tag-content {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        color: #666;
                    }
					> .word-number {
						width: 50px;
						text-align: right;
					}
				}
			}
			.center-content {
				width: 430px;
				margin-left: 40px;
				margin-right: 40px;
				> .field-type-list {
					display: flex;
					border-bottom: 1px solid #0000000d;
					> .item {
						flex: 1;
						font-size: 16px;
						color: #00000066;
						text-align: center;
						margin-bottom: 20px;
						cursor: pointer;
						/* &:first-child{
                            text-align: left;
                        }
                        &:last-child{
                            text-align: right;
                        } */
						&.active {
							color: #000000;
							font-weight: 500;
						}
					}
				}
				> .engine-list {
					margin-top: 20px;
					display: flex;
					flex-wrap: wrap;
					.item {
                        position: relative;
                        overflow: hidden;
						display: flex;
                        align-items: center;
						cursor: pointer;
						width: 130px;
						height: 44px;
						background-color: #ffffff;
						box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
						box-sizing: border-box;
						border: 1px solid #ffffff;
						border-radius: 6px;
						// line-height: 44px;
						margin-bottom: 20px;
						&:nth-child(2),
						&:nth-child(5),
						&:nth-child(8),
						&:nth-child(11),
						&:nth-child(14),
						&:nth-child(17),
						&:nth-child(20),
						&:nth-child(23),
						&:nth-child(26),
						&:nth-child(29),
						&:nth-child(32),
						&:nth-child(35),
						&:nth-child(38),
						&:nth-child(41),
						&:nth-child(44),
						&:nth-child(47),
						&:nth-child(50),
						&:nth-child(53),
						&:nth-child(56),
						&:nth-child(59) {
							margin: 0 20px;
						}
						&.active {
							border-color: #000000;
							// color: #21B780;
						}
						&[disabled],
						&:disabled {
							cursor: not-allowed;
							// pointer-events: none;
							opacity: 0.5;
						}
						&.add-engine {
							justify-content: center;
							svg {
								font-size: 20px;
							}
						}
						> .engine-icon {
							margin-left: 8px;
							font-size: 22px;
						}
						> .engine-name {
							font-size: 13px;
                            line-height: 24px;
							flex: 1;
							margin-left: 5px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						> .engine-more {
							width: 20px;
                            height: 100%;
                            line-height: 44px;
							text-align: center;
							border-radius: 0 6px 6px 0;
							&:hover {
								background-color: #ededed;
							}
							svg {
								font-size: 14px;
							}
						}
					}
				}
			}
			.right-content {
				flex: 1;
				padding: 20px;
				max-width: 400px;
				min-width: 250px;
				min-height: 304px;
                max-height: 619px;
                overflow-y: auto;
				background-color: #ededed;
				border: 1px solid rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				border-radius: 8px;
				color: #000000;
				::v-deep p{
                    line-height: 22px;
                    font-size: 14px;
                }
			}
		}
		> .advanced-setup-content {
			min-width: 957px;
			max-width: 1310px;
			margin: auto;
			margin-top: 20px;
			background-color: #ffffff;
			border: 1px solid rgba(0, 0, 0, 0.1);
			box-sizing: border-box;
			border-radius: 6px;
			> .title-content {
				display: flex;
				align-items: center;
				height: 50px;
				> .left {
					width: 95px;
					margin-left: 40px;
					.title {
						font-weight: 500;
						font-size: 15px;
						line-height: 21px;
						color: #000000;
						svg {
							font-weight: 600;
							margin-left: 6px;
						}
					}
					.subtitle {
						font-size: 12px;
						line-height: 17px;
						color: #00000066;
						margin-top: 4px;
					}
				}
				> .center {
					flex: 1;
					height: 100%;
					
				}
				> .right {
					width: 60px;
					height: 100%;
					line-height: 70px;
					text-align: center;
					font-size: 20px;
					cursor: pointer;
					display: flex;
					align-items: center;
					svg {
						transition: transform 0.2s linear;
					}
					&.show {
						svg {
							transform: rotateZ(135deg);
						}
					}
				}
			}
			.advanced-content {
				border-top: 1px solid #0000001a;
				margin: 0 40px 40px 40px;
			}
		}
	}
    .setting-content{
        width: 860px;
        margin: 0 auto;
        .project-info{
            ::v-deep .el-input__inner{
                border: none;
                font-size: 15px;
                font-weight: 600;
                color: #000000;
            }
        }
        .language-setting-content {
            background-color: #F8F8F8;
            border-radius: 6px;
            .target-select {
                ::v-deep .el-tag {
                    margin: 0 4px;
                    &.el-tag--info {
                        border-color: #F2F2F2;
                        background-color: #F2F2F2;
                        color: #000000;
                        font-size: 15px;
                    }
                }
                ::v-deep .el-tag--mini {
                    height: 26px;
                    line-height: 26px;
                }
            }
        }
        .language-list {
            padding: 0 20px 15px 20px;
            border-top: 1px solid #E6E6E6;
            box-sizing: border-box;
            margin-top: 15px;
            max-height: 240px;
            overflow-y: auto;
            .item {
                margin-top: 15px;
                display: flex;
                align-items: center;
                .compare-btn {
                    width: 32px;
                    height: 32px;
                    margin-left: 20px;
                    background-color: #fff;
                    border-radius: 50px;
                    border: 1px solid #0000001A;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    cursor: pointer;
                    &:hover {
                        border-color: #000000;
                    }
                    img {
                        width: 26px;
                    }
                }
                .engine-select {
                    width: 220px;
                    margin-left: 20px;
                    ::v-deep .el-input--prefix {
                        .el-input__inner {
                            padding-left: 45px;
                            font-weight: normal;
                        }
                    }
                    ::v-deep .el-input__prefix {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        left: 15px;
                    }
                }
            }
        }
        .tab-list {
            border-bottom: 1px solid #0000001A;
            height: 100%;
            display: flex;
            > .item {
                height: 100%;
                display: flex;
                padding-bottom: 15px;
                align-items: center;
                color: #00000066;
                font-size: 15px;
                box-sizing: border-box;
                margin-right: 40px;
                cursor: pointer;
                &.active {
                    color: #000000;
                    font-weight: 600;
                    border-bottom: 2px solid #000000;
                }
            }
        }
        .action-content {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #f8f8f8;
        }
        ::v-deep .el-table .cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .qa-setting {
            .block-title{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .option-list{
                display: flex;
                margin-bottom: 10px;
                flex-wrap: wrap;
                .item{
                    width: 25%;
                    padding: 12px 0;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }
        }
        .backup-engine-wapper {
            .group-name {
                font-size: 12px;
            }
            .engine-group {
                margin-top: 20px;
                margin-bottom: 20px;
                display: grid;
                grid-gap: 15px;
                grid-template-columns: repeat(auto-fill,minmax(119px,1fr));
                .item {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #F8F8F8;
                    border-radius: 4px;
                    box-sizing: border-box;
                    border: 1px solid transparent;
                    font-size: 18px;
                    cursor: pointer;
                    &.active {
                        border-color:#000000;
                        cursor: default;
                    }
                    .name {
                        font-size: 12px;
                        margin-left: 4px;
                    }
                }
            }
        }
        ::v-deep .el-input-number__decrease{
            background: rgb(248, 248, 248);
        }
        ::v-deep .el-input-number__increase{
            background: rgb(248, 248, 248);
        }
    }
    .remark-input {
        ::v-deep .el-textarea__inner {
            background-color: #F8F8F8;
            border: none;
            border-radius: 10px;
            padding: 10px 15px;
        }
    }
	.term-extract-list {
		margin-bottom: 40px;
		.tips {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
			margin-bottom: 20px;
		}
		.retrans-tips {
			margin-top: 10px;
		}
		.el-button--text {
			color: $font-primary-color;
			&:hover,
			&foucs {
				color: $font-primary-color-hover;
			}
			&.delete-btn {
				color: $font-danger-color;
				&:hover,
				&foucs {
					color: $font-danger-color-hover;
				}
			}
		}
	}
    .mem-matching-setting{
        ::v-deep .el-input__inner{
            border-radius: 50px;
        }
    }
}
.bold-form-label{
    ::v-deep .el-form-item__label{
        font-weight: bold;
        color: #000;
    }
}
</style>
